import React from 'react'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import './Form.css'
import Services from './../../../content/accordion/sluzby'

class Form extends React.Component {
  static defaultProps = {
    name: 'Newsletter',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Formulář byl úspěšně odeslán',
    errorMessage:
      'Vyskytl se problém a Vaše zpráva nebyla odeslána, kontaktujte nás prosím prostřednictvím emailu',
  }

  state = {
    alert: null,
    name: this.props.name,
    firstname: null,
    lastname: null,
    company: null,
    emailAddress: null,
    disabled: false,
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const { firstname, lastname, emailAddress, name } = this.state
    const formData = {
      firstname,
      lastname,
      emailAddress,
      'form-name': name,
    }
    const data = formData
    this.setState({ disabled: true })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': this.state.name, ...this.state }),
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          disabled: false,
        })
        window.location.href = 'https://ekp.cz/dekujeme-newsletter'
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage,
        })
      })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const { name, subject, action } = this.props
    const { firstname, lastname, company, emailAddress } = this.state
    return (
      <form
        className="Form"
        name={name}
        action={action}
        onSubmit={this.handleSubmit}
        data-netlify="true"
      >
        {this.state.alert && (
          <div className="Form--Alert">{this.state.alert}</div>
        )}
        <div className="Form--Group">
          <label className="Form--Label">
            <input
              className={`Form--Input Form--InputText ${
                this.state.firstname ? 'Form--Input--NonEmpty' : ''
              }`}
              type="text"
              placeholder="Firstname"
              name="firstname"
              value={firstname}
              onChange={this.handleChange}
              required
            />
            <span>Jméno:</span>
          </label>
          <label className="Form--Label">
            <input
              className={`Form--Input Form--InputText ${
                this.state.lastname ? 'Form--Input--NonEmpty' : ''
              }`}
              type="text"
              placeholder="Lastname"
              name="lastname"
              value={lastname}
              onChange={this.handleChange}
              required
            />
            <span>Příjmení:</span>
          </label>
        </div>

        <label className="Form--Label">
          <input
            className={`Form--Input Form--InputText ${
              this.state.emailAddress ? 'Form--Input--NonEmpty' : ''
            }`}
            type="company"
            placeholder="Company"
            name="company"
            value={company}
            onChange={this.handleChange}
            required
          />
          <span>Společnost:</span>
        </label>

        <label className="Form--Label">
          <input
            className={`Form--Input Form--InputText ${
              this.state.emailAddress ? 'Form--Input--NonEmpty' : ''
            }`}
            type="email"
            placeholder="Email"
            name="emailAddress"
            value={emailAddress}
            onChange={this.handleChange}
            required
          />
          <span>E-mail:</span>
        </label>

        <input
          className="Form--Button Form--SubmitButton"
          type="submit"
          value="Odebírat"
          disabled={this.state.disabled}
        />
      </form>
    )
  }
}

export default Form
