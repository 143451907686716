import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import { graphql } from 'gatsby'
import './styles.css'
import NewsletterForm from './../../components/Default/NewsletterForm'
import Fade from 'react-reveal/Fade'
import { UnderlineYellow } from '../../components/Typography'

const NewsletterPage = ({ data: { page } }) => {
  const { frontmatter } = page
  const { html } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />
      <Fade bottom>
        <div
          className="container NewsletterPage"
          dangerouslySetInnerHTML={{ __html: page.html }}
        ></div>
      </Fade>
      <Fade bottom>
        <div className="container NewsletterPage--Form--Background">
          <h2 className="NewsletterPage--h2white">Odběr newsletteru</h2>
          <UnderlineYellow />
          <NewsletterForm name="Newsletter" />
        </div>
      </Fade>
    </Layout>
  )
}

export default NewsletterPage

export const pageQuery = graphql`
  query NewsletterPage {
    page: markdownRemark(frontmatter: { slug: { eq: "newsletter" } }) {
      ...Meta
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
